<template>
  <div class="solution_content page">
    <div class="page_header_img">
      <Banner :bannerList="bannerList"></Banner>
    </div>
    <div class="page_body_con">
      <div class="nav flex-space-center">
        <div class="page_location">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">
              <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/solution' }">解决方案</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/solution/coldchain/index', query: { type: 3 } }"
              >智慧冷链</el-breadcrumb-item
            >
            <el-breadcrumb-item>案例</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- <div class="flex-space-center foodbox">
        <template v-for="item in solutionList">
          <select-group :options="item" @bindchange="bindchange"></select-group>
        </template>
      </div> -->
      </div>
      <div class="margin-tb-15">
        <el-row>
          <el-col :span="20" class="grey_bg moment-row">
            <div class="title ta-l">{{ solutionInfo.title }}</div>
            <div class="infobox">发布日期：{{ solutionInfo.create_time }} 发布者：中集智本</div>
            <div class="info" v-html="solutionInfo.content"></div>
          </el-col>
          <el-col :span="4" class="moment-rt">
            <div class="page_title_name newtitle">
              <h3>解决方案</h3>
              <span>case solution</span>
            </div>
            <div class="hort">
              <span class="title">方案推荐</span>
              <div class="hort_list">
                <div
                  class="gray_bg_new"
                  v-for="item in hortlist"
                  :key="item.id"
                  :style="{ backgroundImage: 'url(' + item.pic + ')' }"
                >
                  <div class="hort_item" @click="bindPrevNext(item.id)">
                    <h6>{{ item.title }}</h6>
                    <p class="hort_desc line3">{{ item.desc }}</p>
                    <p>{{ item.create_time }} 中集智本</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-center">
              <img :src="require('@/assets/images/solution/common.png')" class="solution_image" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import SelectGroup from "@/components/SelectGroup.vue";
import Banner from "@/components/Banner.vue";
export default {
  data() {
    return {
      solutionList: [],
      solutionInfo: "",
      hortlist: [],
      bannerList: [],
    };
  },
  components: {
    SelectGroup,
    Banner,
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.path == "/solution/coldchain/detail") {
          this.getSolutionDetail(route.query.id);
          this.id = route.query.id;
        }
      },
      immediate: true,
    },
  },
  activated() {
    // window.scrollTo(0, 0);
    // this.getTree();
    setTimeout(() => {
      this.bannerList = this.$store.state.bannerList.filter((item) => {
        let sonarr = item.type.split("|");
        return sonarr.includes("2");
      });
    }, 500);
  },

  methods: {
    bindPrevNext(id) {
      this.$router.push({
        path: "/solution/coldchain/detail",
        query: {
          id: id,
        },
      });
    },
    getSolutionDetail(id) {
      this.$http({
        method: "get",
        url: "website_content_detail",
        params: {
          id,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.solutionInfo = res.data;
          let arr = [];
          if (res.data.prev) {
            arr.push(res.data.prev);
          }
          if (res.data.next) {
            arr.push(res.data.next);
          }

          this.hortlist = arr;
        }
      });
    },
    getTree() {
      this.$http({
        method: "get",
        url: "tree",
        params: {
          limit: 1000,
          type: 2,
          forEdit: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.solutionList = res.data[0].children[0].children;
          // console.log(res.data[0].children)
        }
      });
    },
    bindchange(e) {
      console.log("父", e);
    },
  },
};
</script>
<style>
.info p img {
  width: 100%;
  height: auto;
}
</style>
<style lang="scss" scoped>
.solution_content {
  height: auto;
}
.grey_bg {
  background: #fafafa;
}
.infobox {
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
img {
  width: 350px;
  height: 240px;
}
.moment-row {
  // text-align: center;
  // background: #f4f4f4;
  // overflow: hidden;
  // float: right;
  margin-left: 8%;
  width: 64%;
  padding: 0px 15px 30px;
  margin-bottom: 60px;
  .title {
    font-size: 42px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    height: 42px;
    line-height: 42px;
    margin-bottom: 30px;
  }
  .info {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    // text-align: ;
  }
}
.moment-rt {
  // padding: 0px 15px 30px 15px;
  background: #fafafa;
  margin-left: 15px;
  padding: 30px 15px 30px 15px;
  height: 800px;
  // background: #f2f2f2;
  .newtitle {
    color: #000;
    h3 {
      color: #000;
    }
    span {
      color: #000;
    }
  }
  .hort {
    margin: 30px auto 5px;
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      line-height: 46px;
      height: 46px;
    }
    .hort_list {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      h6 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fff;
        line-height: 24px;
        height: auto;
      }
      .gray_bg_new {
        cursor: pointer;
        margin-bottom: 15px;
        background-size: cover;

        // opacity: 0.5;
        border-radius: 0px 0px 0px 0px;
        color: #ffffff;
        line-height: 18px;
        .hort_item {
          background: rgba(0, 0, 0, 0.51);
          padding: 15px;
          //   background: url("../../assets/images/main/company_img.png") no-repeat;
        }
        .hort_desc {
          margin: 5px 0 10px;
        }
        // opacity: 1;
      }
    }
  }
  .solution_image {
    width: 100%;
    height: auto;
    margin-top: 15px;
  }
}
.custom_box {
  height: 48px;
  display: flex;
  // margin-right: 60px;
  justify-content: flex-end;
  margin: 30px 15px 60px 0;
  padding-bottom: 90px;
  .el-card {
    width: 420px;
    height: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 800;
    color: #595959;
  }
  .button_yiliao {
    width: 120px;
    height: 60px;
    background: #006acd;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
  .button_shengxian {
    width: 120px;
    height: 60px;
    background: #ffc700;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
  .button_yangzhi {
    width: 120px;
    height: 60px;
    background: #53b26d;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
}
.solutionIndex {
  height: auto;
  // background: #f5f5f5;
  // padding:30px 0;
  // background: url("../../assets/images/service/Rectangle.png") 100% 100%;
}
.nav {
  padding-top: 40px;
  padding-bottom: 30px;
  margin-left: 8%;
}
.desc {
  padding: 5px 0;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #575757;
  line-height: 28px;
  letter-spacing: 1px;
  text-indent: 40px;
}
</style>
